// dependencies
import React from 'react'

// styles
import styles from './layout.module.css'

const Footer = () => {
    return (
        <footer className={styles.Footer}>
            <p className={styles.FooterText}>
                Website created by&nbsp;
                <a
                    href="https://imjackson.dev"
                    target="_blank"
                    rel="noreferrer noopener"
                >
                    Jackson
                </a>
            </p>
        </footer>
    )
}

export default Footer
