// dependencies
import React from 'react'
import { Link } from 'gatsby'

// styles
import styles from './layout.module.css'

// images
import logo from '../images/logo.svg'

const Header = () => {
    return (
        <header className={styles.Header}>
            <Link to="/">
                <img src={logo} alt="SewFabulous." className={styles.Logo} />
            </Link>
        </header>
    )
}

export default Header
