// dependencies
import React from 'react'
import PropTypes from 'prop-types'

// components
import Header from './header'
import Footer from './footer'

// styles
import styles from './layout.module.css'
import './layout.css'

const Layout = ({ children }) => {
    return (
        <div className={styles.LayoutContainer}>
            <Header />
            <div className={styles.Main}>{children}</div>
            <Footer />
        </div>
    )
}

Layout.propTypes = {
    children: PropTypes.node.isRequired,
}

export default Layout
